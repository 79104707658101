//
// Generic Class for Google Analytics
// @author: Mark Morris
//

class MusiclineAnalytics {

    constructor(config) {
        this.debug = false;
        // this.clickEventClass = config.clickEventClass;
        //
        // // Auto Click Events
        // let events = document.querySelectorAll(this.clickEventClass);
        // console.log('Found '+events.length+' gtag elements on page.');
        // for(let x=0; x < events.length; x++) {
        //     events[x].addEventListener('click', (button) => {
        //         MusiclineAnalytics.EventTrigger(button);
        //     }, true);
        // }
    }

    // Output arguments to the Debug console
    // if "Debug Mode" is enabled
    _debug(info) {
        if (!this.debug) {
            return;
        }
        console.log(info);
    }

    static EventTrigger(e) {
        // e.preventDefault();
        // let event_type = e.target.getAttribute('data-event');
        // let event_category = e.target.getAttribute('data-category');
        // let event_label = e.target.getAttribute('data-label');
        // let event_value = e.target.getAttribute('data-value');
        // let event_parameters = {
        //     'event_category': event_category,
        //     'event_label': event_label,
        //     'event_value': event_value
        // };
        // MusiclineAnalytics.ClickEvent(event_type, event_parameters);
    }

    static ClickEvent(event, parameters = {}) {
        //
    }

    TrackEvent(cat, action, info = '') {
        //console.log('gtag track event: '+cat+" "+action+ " " + info);
        // Google Analytics
        //ga( 'send', 'event', cat, action, info);

        // Google gTag
        gtag('event', action, { 'event_category': cat, 'event_label': info } );
        sendVisitorData('click', { "Category": cat, "Action": action, "Info": info } );
    }

}
